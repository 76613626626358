/*
 * @Description: AKJERRT
 * @Date: 2022-07-05 15:09:03
 * @LastEditTime: 2022-07-14 10:45:02
 * @FilePath: \linkgap-front\src\views\dealer_balance\thead\componentsColumns.js
 */
export const XYEDMX = [
  {
    title: '时间',
    dataIndex: 'SJ',
    align: 'center',
    key: 'SJ',
  },
  {
    title: '交易方式',
    dataIndex: 'JEFS',
    align: 'center',
    key: 'JEFS',
    scopedSlots: { customRender: 'JEFS' },
  },
  {
    title: '上期余额',
    dataIndex: 'SQYE',
    align: 'center',
    key: 'SQYE',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '增加',
    dataIndex: 'CZ',
    align: 'center',
    key: 'CZ',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '使用',
    dataIndex: 'SY',
    align: 'center',
    key: 'SY',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '结余',
    dataIndex: 'JY',
    align: 'center',
    key: 'JY',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '单号',
    dataIndex: 'DH',
    align: 'center',
    key: 'DH',
    scopedSlots: { customRender: 'DH' },
    width: 300,
  },
]

export const ZHZL = [
  {
    title: '项目',
    dataIndex: 'XM',
    key: 'XM',
    align: 'center',
    width: 100,
  },
  {
    title: '欠款余额',
    align: 'center',
    className: 'headerYellow',
    children: [
      {
        title: '欠款',
        dataIndex: 'QK',
        key: 'QK',
        align: 'center',
        width: 100,
        className: 'headerTextRed',
        scopedSlots: { customRender: 'QK' },
      },
    ],
  },
  {
    title: '可使用额度',
    align: 'center',
    className: 'headerBlue',
    children: [
      {
        title: '上期余额',
        dataIndex: 'SQYE',
        key: 'SQYE',
        align: 'center',
        width: 100,
      },
      {
        title: '本期增加',
        dataIndex: 'BQZJ',
        key: 'BQZJ',
        align: 'center',
        width: 100,
      },
      {
        title: '本期失效额度',
        dataIndex: 'BQSXED',
        key: 'BQSXED',
        align: 'center',
        width: 100,
      },
      {
        title: '本期发货',
        dataIndex: 'BQFH',
        key: 'BQFH',
        align: 'center',
        width: 100,
      },
      {
        title: '可使用结余',
        dataIndex: 'KSYJY',
        key: 'KSYJY',
        align: 'center',
        width: 100,
      },
    ],
  }
]

export const ZJZH = [
  {
    title: '时间',
    dataIndex: 'SJ',
    align: 'center',
    key: 'SJ',
  },
  {
    title: '交易方式',
    dataIndex: 'JEFS',
    align: 'center',
    key: 'JEFS',
    scopedSlots: { customRender: 'JEFS' },
  },
  {
    title: '上期余额',
    dataIndex: 'SQYE',
    align: 'center',
    key: 'SQYE',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '充值',
    dataIndex: 'CZ',
    align: 'center',
    key: 'CZ',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '发货',
    dataIndex: 'FH',
    align: 'center',
    key: 'FH',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '结余',
    dataIndex: 'JY',
    align: 'center',
    key: 'JY',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '单号',
    dataIndex: 'DH',
    align: 'center',
    key: 'DH',
    scopedSlots: { customRender: 'DH' },
    width: 300,
  },
]

export const QKMX = [
  {
    title: '时间',
    dataIndex: 'SJ',
    align: 'center',
    key: 'SJ',
  },
  {
    title: '交易方式',
    dataIndex: 'JEFS',
    align: 'center',
    key: 'JEFS',
    scopedSlots: { customRender: 'JEFS' },
  },
  {
    title: '上期余额',
    dataIndex: 'SQYE',
    align: 'center',
    key: 'SQYE',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '增加',
    dataIndex: 'CZ',
    align: 'center',
    key: 'CZ',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '还款',
    dataIndex: 'FH',
    align: 'center',
    key: 'FH',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '结余',
    dataIndex: 'JY',
    align: 'center',
    key: 'JY',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '单号',
    dataIndex: 'DH',
    align: 'center',
    key: 'DH',
    scopedSlots: { customRender: 'DH' },
    width: 300,
  },
]

export const ZKEMX = [
  {
    title: '时间',
    dataIndex: 'SJ',
    align: 'center',
    key: 'SJ',
  },
  {
    title: '交易方式',
    dataIndex: 'JEFS',
    align: 'center',
    key: 'JEFS',
    scopedSlots: { customRender: 'JEFS' },
  },
  {
    title: '上期余额',
    dataIndex: 'SQYE',
    align: 'center',
    key: 'SQYE',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '增加',
    dataIndex: 'ZJ',
    align: 'center',
    key: 'ZJ',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '减少',
    dataIndex: 'JS',
    align: 'center',
    key: 'JS',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '结余',
    dataIndex: 'JY',
    align: 'center',
    key: 'JY',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '单号',
    dataIndex: 'DH',
    align: 'center',
    key: 'DH',
    scopedSlots: { customRender: 'DH' },
    width: 300,
  },
]